import { memo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import Image from '@/components/Image/Image';
import styles from './index.module.scss';
import Arrow from '@/components/svgs/circle-arrow.svg';
import Insta from '@/components/svgs/instagram-color.svg';
import TikTok from '@/components/svgs/tiktok-shape.svg';
import Link from 'next/link';
import routes from '@/data/routes';
import scrollPage from '@/utils/scroll-page';
import Head from '@/components/Head/Head';
import AboutSlider from '@/components/Home/AboutSlider/AboutSlider';

type Props = {
  className: string;
};

function Home({ className }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);
  const btnScrollRef = useRef<HTMLButtonElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const corsiRef = useRef<HTMLDivElement>(null);
  const socialRef = useRef<HTMLUListElement>(null);
  // const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    btnScrollRef.current?.addEventListener('click', () => {
      scrollPage({ y: corsiRef.current?.offsetTop, duration: 1.6, ease: 'power3.out' });
    });
    const timeline = gsap
      .timeline()
      .fadeIn(bgRef.current, 0.1)
      .fadeIn(titleRef.current, 0.2)
      .fadeIn(imgRef.current, 0.3)
      .fadeIn(btnScrollRef.current, 0.4)
      .fadeIn(socialRef.current, 0.5)
      .fadeIn(corsiRef.current, 0.6);
    // .fadeIn(listRef.current?.childNodes, { stagger: 0.1 }, 0.6);

    return () => {
      timeline?.kill();
    };
  }, []);

  return (
    <main className={classnames(styles.Home, className)} ref={containerRef}>
      <Head />
      <section className={styles.hero}>
        <div className={styles.wrapper} ref={bgRef}>
          <div className={styles.wall}>
            <Image src="hero-bg-2.jpg" alt="Maratone Benessere Donna" />
          </div>
          <h1 className={styles.title} ref={titleRef}>
            I corsi che consigliano alle amiche, sorelle e alle mamme
          </h1>
          <ul className={styles.social} ref={socialRef}>
            <li>
              <a href="https://www.instagram.com/nadinebenesseredonna" target="blank">
                <Insta />
              </a>
            </li>
            <li>
              <a href="http://www.tiktok.com/@lazarevanadine" target="blank">
                <TikTok />
              </a>
            </li>
          </ul>
          <figure className={styles.nadine}>
            <Image src="hero-home.png" alt="Maratone Benessere Donna" />
          </figure>
        </div>
        <button className={styles.scrollDown} ref={btnScrollRef}>
          Scopri i corsi <Arrow />
        </button>
      </section>
      <section className={styles.corsi} ref={corsiRef}>
        <Link href={routes.MaratonaBenessereDonna.path} prefetch={false}>
          <a>
            <Image src="box-benessere.png" alt="Maratone Benessere Donna" />
            <Arrow />
            <h3>Maratone BenEssereDonna</h3>
          </a>
        </Link>
        <Link href={routes.MaratonaLatoB.path} prefetch={false}>
          <a>
            <Image src="box-latob.png" alt="Maratone LatoB" />
            <Arrow />
            <h3>Maratone LatoB</h3>
          </a>
        </Link>
        <Link href={routes.Autovalutazione.path} prefetch={false}>
          <a>
            <Image src="box-diastasi.png" alt="Test di autovalutazione della Diastasi" />
            <Arrow />
            <h3>Test di autovalutazione della Diastasi</h3>
          </a>
        </Link>
      </section>
      <AboutSlider />
    </main>
  );
}

export default memo(Home);
