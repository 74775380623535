import React from 'react';
import Image from '@/components/Image/Image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy, Navigation, Autoplay } from 'swiper';
import Next from '@/assets/svgs/next.svg';
import Prev from '@/assets/svgs/prev.svg';
import styles from './AboutSlider.module.scss';

SwiperCore.use([Lazy, Navigation, Autoplay]);

function AboutSlider() {
  const sliderImages: string[] = [
    'certs/1.jpg',
    'certs/2.jpg',
    'certs/3.jpg',
    'certs/4.jpg',
    'certs/5.jpg',
    'certs/6.jpg',
    'certs/7.jpg',
    'certs/8.jpg',
    'certs/9.jpg',
    'certs/10.jpg',
    'certs/11.jpg',
    'certs/12.jpg',
    'certs/13.jpg',
    'certs/14.jpg',
    'certs/15.jpg',
    'certs/16.jpg',
    'certs/17.jpg',
    'certs/18.jpg',
    'certs/19.jpg',
    'certs/20.jpg',
    'certs/21.jpg',
    'certs/22.jpg',
    'certs/23.jpg'
  ];

  return (
    <>
      <section className={styles.about}>
        <div className={styles.about__bg}>
          <Image src="hero-bg-2.jpg" alt="Maratone Benessere Donna" />
        </div>
        <div className={styles.about__wrapper}>
          <div className={styles.about__c}>
            <h3>Chi sono</h3>
            <p>Mi chiamo Nadine e sono una istruttrice di fitness</p>
            <br />
            <ul>
              <li>Ho più di 10 anni di esperienza nel mondo dello sport</li>
              <li>
                Ha aiutato oltre 8000 donne a ritrovare bellezza, salute e fiducia nel proprio corpo senza allenamenti
                estenuanti
              </li>
              <li>
                Vi aiuterò a rafforzare i muscoli profondi e superficiali, a creare sollievo e forme femminili e
                mantenere le vostre articolazioni sane
              </li>
            </ul>
            <i>Diplomi e certificati possono essere visualizzati di seguito</i>
          </div>
          <figure>
            <Image src="about-me.png" alt="Maratone Benessere Donna" />
          </figure>
        </div>
      </section>
      <div className={styles.gallerySliderWrapper}>
        <Swiper
          loop={true}
          speed={1000}
          autoplay={true}
          slidesPerView={2}
          spaceBetween={15}
          allowTouchMove={true}
          lazy={{ loadPrevNext: true }}
          className={styles.gallerySlider}
          navigation={{ nextEl: '.btn-slider-next', prevEl: '.btn-slider-prev' }}
          breakpoints={{
            740: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40
            },
            1360: {
              slidesPerView: 4,
              spaceBetween: 50
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 60
            },
            1920: {
              slidesPerView: 4,
              spaceBetween: 70
            }
          }}
        >
          {sliderImages.map((el, i) => {
            return (
              <SwiperSlide key={i} className={styles.gallerySliderSlide}>
                <figure data-swiper-parallax-x="200">
                  <Image src={el} alt="Maratone Benessere Donna" />
                </figure>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={styles.gallerySliderNav}>
          <div className="btn-slider-prev">
            <Prev />
          </div>
          <div className="btn-slider-next">
            <Next />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSlider;
